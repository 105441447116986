// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/external/orders/order.ts"
);
import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

/**
 * @file order.ts
 * @description Uses our own (Biosonah API) logic to handle orders.
 */
import axios from 'axios';

export async function addItemToOrder(
  customerId: number | null,
  orderId: string | null,
  lineId: string | null,
  productVariantId: string,
  quantity: number,
  unit: 'KG' | 'STK' | 'N/A',
) {
  const apiUrl = window.ENV.BIOSONAH_API_URL;
  try {
    const response = await axios.post(
      `${apiUrl}/order/add_item`,
      {
        customerId,
        vendureOrderId: orderId ? parseInt(orderId) : null,
        vendureOrderLineId: lineId ? parseInt(lineId) : null,
        productVariantId,
        quantity,
        unit,
      },
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error: any) {
    // Check if error is an AxiosError and has a response
    if (error.response && error.response.data) {
      return error.response.data;
    }

    // If no response, throw the error to be handled elsewhere
    throw error;
  }
}

export async function getOrdersForCustomer() {
  const apiUrl = window.ENV.BIOSONAH_API_URL;
  try {
    const response = await axios.get(`${apiUrl}/order/me`, {
      withCredentials: true,
    });

    return response.data;
  } catch (error: any) {
    // Check if error is an AxiosError and has a response
    if (error.response && error.response.data) {
      return error.response.data;
    }

    // If no response, throw the error to be handled elsewhere
    throw error;
  }
}
