// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/hooks/useOrders.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/hooks/useOrders.tsx");
  import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getOrdersForCustomer } from '~/providers/external/orders/order';
// Create the context with a default value
const OrdersContext = createContext(undefined);
export const OrdersProvider = ({
  customerId,
  children
}) => {
  _s();
  const [orders, setOrders] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const fetchOrders = async () => {
    setOrdersLoading(true);
    try {
      const result = await getOrdersForCustomer();
      if (result && result.code === 200) {
        setOrders(result.items.orders);
        setStatistics(result.items.statistics);
      }
    } catch (error) {
      console.error(error);
    }
    setOrdersLoading(false);
  };
  const refreshOrders = () => {
    fetchOrders();
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  return <OrdersContext.Provider value={{
    orders,
    statistics,
    ordersLoading,
    fetchOrders,
    refreshOrders
  }}>
      {children}
    </OrdersContext.Provider>;
};
_s(OrdersProvider, "Q37T55PFmtQVTUMkE6nw145P7jI=");
_c = OrdersProvider;
export const useOrders = () => {
  _s2();
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error('useOrders must be used within an OrdersProvider');
  }
  return context;
};
_s2(useOrders, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
var _c;
$RefreshReg$(_c, "OrdersProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;